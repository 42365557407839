@use "sass:math";

@import '@angular/cdk/overlay-prebuilt.css';
@import "reset";
@import "mixins";
@import "lang-icons";
@import "flex";
@import "animation";
@import "classes";
@import "modal";
@import "mobile-menu";
@import "icons";
@import "components/gallery";

:root {
  touch-action: pan-x pan-y;
  font: 16px 'Fira Sans', sans-serif;
  color: #393939;

  --sat: env(safe-area-inset-top, 0);
  --sar: env(safe-area-inset-right, 0);
  --sab: env(safe-area-inset-bottom, 0);
  --sal: env(safe-area-inset-left, 0);

  height: 100%;

  * {
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  height: 100%;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}
h2 {
  font-size: 1.875rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.375rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}

input[type=checkbox][role=checkbox] {
  width: 1.375em;
  height: 1.375em;
  border-radius: 1em;
  background-repeat: no-repeat;
  background-position: 2px 3px;
  background-size: 75%;
  background-color: rgba(85, 102, 255, 0.1);
  vertical-align: middle;
  cursor: pointer;
  transition: .2s background-color ease-in-out;
  &:checked {
    background-color: #FFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%235566FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
}
input[type=checkbox][role=switch] {
  $width: 3em;
  $height: 1.5em;
  $circle-radius: math.div(1.875em, 2);
  $circle-shift: math.div($height - $circle-radius*2, 2);
  width: $width;
  height: $height;
  border-radius: .75em;
  background-color: #C2C9E0;
  background-image: none;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
  outline: none !important;
  &::after {
    @include circle($circle-radius);
    content: '';
    display: block;
    background-color: #FFF;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    transform: translate($circle-shift, $circle-shift);
    transition: transform .15s ease-in-out;
  }
  &:checked {
    background-color: #5F65FF;
    &::after {
      $check: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%235566FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e";
      transform: translate($width - ($circle-radius*2) - $circle-shift, $circle-shift);
      background: url($check) no-repeat center/75%, #FFF;
    }
  }
}

input[type=radio]:not(.nostyle) {
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.05em solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
  &::before {
    content: "";
    background-color: currentColor;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }
  &:checked::before {
    transform: scale(1);
  }
}

input[type=text],
input[type=tel],
input[type=email],
input[type=number],
input[type=date] {
  outline: none;
  background: #FFFFFF;
  border: 1.5px solid #C9CFE3;
  border-radius: .875em;
  line-height: 1.5;
  padding: .75em 1em;
  vertical-align: middle;
  transition: .2s ease-in-out border-color;
  &::placeholder {
    color: #ACB4CD;
  }
  &:focus-visible {
    border-color: #5566FF;
  }
  &.invalid {
    border-color: #FF6C4C;
  }
  &:disabled {
    opacity: .6;
  }
}
input[type=date] {
  &::-webkit-calendar-picker-indicator { opacity: .4 }
  -webkit-appearance: none;
  height: calc(1.5px*2 + .75em*2 + 1.5em);
}

textarea {
  background: #F7F8FC;
  border: 2px solid #C9CFE3;
  border-radius: 14px;
  padding: 1.75em;
  &::placeholder {
    color: #ACB4CD;
  }
  &:focus-visible {
    border: 2px solid #5566FF;
  }
  &:disabled {
    color: #ACB4CD;
  }
}
