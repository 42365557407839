@import "mixins";

@keyframes overflowHiddenAuto {
  0% {
    overflow: visible;
  }
  100% {
    overflow: auto;
  }
}

.modal-overlay-wrapper {
  &.cdk-global-overlay-wrapper {
    @include not_portrait_phone {
      animation: overflowHiddenAuto .2s 1 forwards steps(1, jump-end);
      pointer-events: auto;
    }
  }

  .cdk-dialog-container {
    outline: none !important;
    background: #F7F8FC;
    @include portrait_phone {
      padding-top: var(--sat);
      overflow: auto;
    }
    .modal-title {
      text-align: center;
    }
    .modal-x-btn {
      justify-self: end;
    }
  }

  .dialog-centered {
    .cdk-dialog-container {
      @include not_portrait_phone {
        border-radius: 20px;
      }
      animation: modalIn .1s ease-out 1 forwards;
    }

    .modal-header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      height: 70px;
      padding: 0 1.5em 0 0;
    }

    .modal-content {
      padding: 0 1.5em 1.5em;
      @include portrait_phone {
        padding: 0 0.5em calc(0.75em + var(--sab));
      }
    }
  }

  .dialog-bottom-sided {
    .cdk-dialog-container {
      border-radius: 20px;
      padding-bottom: calc(var(--sab) + 20px);
      animation: modalIn .1s ease-out 1 forwards;
    }

    .modal-content {
      padding: 0 1.5em 1.5em;
      @include portrait_phone {
        padding: 0 0.5em 0.75em;
      }
    }

    .modal-header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      height: 50px;
      padding: 0 10px 0 40px;
    }

    .modal-x-btn {
      width: 30px;
      height: 30px;
      justify-self: end;
    }
  }

  .modal-x-btn {
    @include mask('~src/assets/ic_close_mask.svg', #C2C9E0, 60%);
    width: 2.5em;
    height: 2.5em;
  }
}
