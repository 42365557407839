@import "mixins";

.flip-list-move {
  transition: transform .2s;
}

.icon {
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask-position: center;
  mask-position: center;
  vertical-align: middle;
}

.btn {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  gap: .25em;
  .icon {
    width: 1.75rem;
    height: 1.75rem;
    background-size: 100%;
    //noinspection CssInvalidPropertyValue
    image-rendering: -webkit-optimize-contrast;
  }
  &:disabled {
    opacity: .3;
  }
}

.btn-round, .btn-blue, .btn-grey, .btn-orange, .btn-muted, .btn-green {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  transition: background-color .1s ease-out, color .1s ease-out;
  .icon {
    width: 1.75rem;
    height: 1.75rem;
    background-size: 100%;
    //noinspection CssInvalidPropertyValue
    image-rendering: -webkit-optimize-contrast;
  }
  .icon ~ .text {
    margin-left: .5rem;
  }
  &:disabled {
    opacity: .3;
  }
}
.btn-blue, .btn-grey, .btn-orange, .btn-muted, .btn-green {
  padding: 1rem 1.5rem;
}
.btn-blue, .btn-grey, .btn-orange, .btn-green {
  border: none;
  border-radius: 1rem;
}
.btn-blue, .btn-orange, .btn-green {
  color: #FFF;
}
.btn-blue {
  background-color: #5566FF;
  &:disabled {
    background-color: #C7CCFD;
    opacity: 1;
  }
  &:hover:not(:disabled) {
    background-color: #7482FC;
  }
}
.btn-green {
  background-color: #59B44B;
  &:disabled {
    background-color: #CFE9CC;
    opacity: 1;
  }
  &:hover:not(:disabled) {
    background-color: #4EA93F;
  }
}
.btn-orange {
  background-color: #FF6C4C;
  &:hover {
    background-color: #FD795C;
  }
}
.btn-grey {
  background: #E5E8F3;
  color: #000;
}
.btn-muted {
  border-radius: 1rem;
  color: #ACB4CD;
  background-color: transparent;
  border: 1.5px solid #E5E8F3;
}
.btn-round {
  background: transparent;
  border: 1px solid #5566FF;
  border-radius: 3rem;
  color: #5566FF;
  //min-width: 125px;
  padding: 0 1.25rem;
  height: 3.25rem;
  .icon {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }
  &.selected {
    background: #5566FF;
    color: #FFF;
  }
}

.btn-orange-circle {
  background: linear-gradient(95deg, #FF795C -53.34%, #FFD15C 82.55%);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.07);
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.absolute-fill {
  position: absolute !important;
  left: 0; right: 0; top: 0; bottom: 0;
}

.d-none {
  display: none !important;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.center-items {
  display: grid;
  place-items: center;
}

.nowrap {
  white-space: nowrap;
}

.pos-absolute {
  position: absolute;
}
.pos-relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.ms-auto {
  margin-left: auto;
}
.me-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

$orientations:
  "t" "top",
  "b" "bottom",
  "s" "left",
  "e" "right";
$margin-sizes:
  "1" .25em,
  "2" .5em,
  "3" .75em,
  "4" 1em,
  "5" 1.5em;
@each $name, $size in $margin-sizes {
  .p-#{$name} {
    padding: $size;
  }
  .m-#{$name} {
    padding: $size;
  }
  @each $orientation, $prop in $orientations {
    .m#{$orientation}-#{$name} {
      margin-#{$prop}: $size;
    }
    .p#{$orientation}-#{$name} {
      padding-#{$prop}: $size;
    }
  }
}

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}

.validation {
  color: #FF6C4C;
  font-size: .75em;
  padding: .75em 0;
  &::before {
    display: inline-block;
    content: '';
    width: 1em;
    height: 1em;
    background: url(~src/assets/exclamation_tri.svg) no-repeat center;
    vertical-align: -1px;
    margin-right: .5em;
  }
}

.form-input {
  margin-bottom: 1em;
  label {
    margin-bottom: .5em;
    display: block;
    font-size: .875em;
    font-weight: 500;
    transition: .2s ease-in-out color;
    color: #ACB4CD;
    letter-spacing: -.3px;
  }
  input {
    width: 100%;
  }
  .validation {
    display: none;
  }
  &.invalid {
    label {
      color: #FF6C4C;
    }
    input {
      border-color: #FF6C4C !important;
    }
    .validation {
      display: block;
    }
  }
}

.hidden {
  visibility: hidden;
}

.card-content {
  display: block;
  width: 100%;
  height: 100%;
}

.round-section {
  padding: 1.5em;
  background: #FBFCFF;
  border: 1px solid #DDE0EC;
  border-radius: .875em;
  @include portrait_phone {
    padding: 1em;
    border: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  }
  .section-header {
    color: #393939;
    margin-bottom: 2rem;
  }
}

.text-blue {
  color: #5566FF;
}
.text-green {
  color: #59B44B;
}
.text-grey {
  color: #ACB4CD;
}
.text-red {
  color: #FF6C4C;
}
.text-yellow {
  color: rgba(244, 188, 80, 1);
}
.text-smallest {
  font-size: .75em;
}
.text-small {
  font-size: .875em;
}

.search {
  display: block;
  position: relative;
  margin-bottom: 1em;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 1em;
    top: 27%;
    height: 44%;
    aspect-ratio: 1;
    background: url(~src/assets/loupe.svg) no-repeat center/cover;
  }
  input[type=text] {
    background: #F0F2F9;
    width: 100%;
    padding: 0.625em 0.75em 0.625em 2.75em;
    border: none;
  }
}

.loading-indicator-backdrop {
  animation: fadeIn 1s ease-out 1 forwards;
  transition: backdrop-filter .2s;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(3px);
  background-color: rgba(255,255,255,.3);
}

.ghost-elements {
  position: relative;
  overflow: hidden;
  &::after {
    display: block;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #FBFCFF88;
    -webkit-mask: linear-gradient(90deg, transparent 0%, #000 50%, transparent 100%);
    mask: linear-gradient(90deg, transparent 0%, #000 50%, transparent 100%);
    top: 0;
    left: 0;
    animation: ghostElementsShine 1s infinite linear;
  }

  .ghost-element {
    background-color: #EBEEF8;
  }

  > svg {
    display: block;
  }
}

.tag-container {
  gap: .75rem;
  display: flex;
  flex-wrap: wrap;
  .tag {
    display: flex;
    align-items: center;
    height: 2.75rem;
    background: #E7E9FC;
    border-radius: 1.75rem;
    padding: 0 1.25rem;
    .text {
      vertical-align: middle;
    }
    .icon {
      @include circle(.825em);
      margin: 0 .5em 0 -.75em;
      width: 1.75em;
      height: 1.75em;
      background-size: 100%;
      //noinspection CssInvalidPropertyValue
      image-rendering: -webkit-optimize-contrast;
    }
  }
}

.vacancy-description-html {
  p {
    padding-bottom: 1.25em;
  }
  h1,h2,h3,h4,h5,h6 {
    padding-bottom: 1em;
  }
  ul {
    list-style: circle;
    padding-bottom: 1.25em;
  }
  li {
    margin-left: 1.75em;
  }
}

.survey-card {
  border-radius: 14px;
  contain: layout paint;
  overflow: hidden;
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
  line-height: 1.2;
  color: #FFF;
}
.survey-card-ratio {
  aspect-ratio: 394/638;
}

.lisa-speech-bubble {
  padding:  1em 2.25em 1em 1.5em;
  background-color: #FFF;
  border-radius: 14px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  font-size: 0.875em;
  animation: blowIn 0.1s 1 forwards ease-out;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 14.7px 8.5px;
    border-color: transparent transparent #ffffff transparent;
  }
}

.times {
  &::before {
    content: '\00D7';
  }
}

.cdk-menu {
  padding: .5em 0;
  background-color: #FFF;
  border-radius: .5em;
  box-shadow: 0 2px 6px 0 #a3a3a3;
  hr {
    height: 1px;
    background-color: #E5E8F3;
  }
}
.cdk-menu-item {
  text-align: left;
  width: 100%;
  display: block;
  padding: .5em 1.25em;
  white-space: nowrap;
  background-color: transparent;
  &:hover {
    color: #FFF;
    background-color: #5566FF;
  }
}
