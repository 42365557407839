$languages: (
  "ru": "russia",
  "en": "united_states"
);

@each $lang, $bg in $languages {
  .lang-#{$lang} {
    background-image: url('~src/assets/chat-options/#{$bg}.png');
  }
}
