@use "sass:math";

@mixin landscape_all {
  @media (orientation: landscape) and (min-width: 600px) {
    @content;
  }
}

@mixin landscape_medium {
  @media (max-width: 1375px) and (orientation: landscape),
         (max-height: 899px) and (orientation: landscape) {
    @content;
  }
}
@mixin landscape_small_2 {
  @media (max-width: 1176px) and (orientation: landscape),
         (max-height: 649px) and (orientation: landscape) {
    @content;
  }
}
@mixin landscape_small_1 {
  @media (max-width: 1132px) and (orientation: landscape),
         (max-height: 639px) and (orientation: landscape) {
    @content;
  }
}

@mixin portrait_phone {
  @media (max-width: 599.98px) {
    @content;
  }
}
@mixin not_portrait_phone {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin portrait_tablet {
  @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait) {
    @content;
  }
}
@mixin portrait_all {
  @media (max-width: 599.98px), (orientation: portrait) {
    @content;
  }
}

@mixin mask($path, $color, $size: 100%) {
  background-color: $color;
  -webkit-mask: url($path) no-repeat center/$size $size;
  mask: url($path) no-repeat center/$size $size;
}

@mixin circle($radius) {
  width: $radius * 2;
  height: $radius * 2;
  border-radius: $radius;
}

// Указываем размер в пикселях из фигмы, получаем размер в em, относительно размера шрифта в карточке
@function calcCardEm($pixels, $baseFontSize: 24) {
  @return math.div($pixels, $baseFontSize) * 1em;
}

@mixin maxLines($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}
