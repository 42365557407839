*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

ul {
  list-style: none;
  padding: 0;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

hr {
  border: 0;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
  padding: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

label {
  display: inline-block; // 1
}

textarea {
  outline: 0;
}

iframe {
  border: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
