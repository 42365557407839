@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(33%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes modalIn {
  from {
    opacity: 0;
    transform: translateY(33%) perspective(200px) rotate3d(1, 0, 0, 7deg);
  }
  to {
    opacity: 1;
    transform: translateY(0) perspective(200px) rotate3d(1, 0, 0, 0deg);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translate3d(33%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-33%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes ghostElementsShine {
  0% {
    transform: rotate(9deg) translateX(-150%) scale(1, 10);
  }
  100% {
    transform: rotate(9deg) translateX(150%)  scale(1, 10);
  }
}

@keyframes blowIn {
  0% {
    transform: scale(.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
