app-desktop-gallery {
  .scroll-container > * {
    flex: 0 0 auto;
  }
}

app-story {
  .app-story-gallery-item {
    width: 100%;
    height: 100%;
    flex: none;
  }
}
