@import "mixins";

.i-arrow-right {
  background-image: url('~src/assets/arrow-right-white.svg');
}
.i-arrow-left-mask {
  $path: '~src/assets/arrow-right-white.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
  transform: scaleX(-100%);
}
.i-lt-mask {
  $path: '~src/assets/ic_lt_mask.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}
.i-gt-mask {
  $path: '~src/assets/ic_lt_mask.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
  transform: scaleX(-100%);
}
.i-info {
  background-image: url('~src/assets/info_button.svg');
}
.i-envelope-outline {
  background-image: url('~src/assets/envelope_outline.svg');
}
.i-phone-outline {
  background-image: url('~src/assets/phone_outline.svg');
}
.i-loupe {
  background-image: url(~src/assets/loupe.svg);
}
.i-info-mask {
  @include mask('~src/assets/info_button.svg', #ACB4CD, 100%);
}
.i-mobile-back {
  @include mask('~src/assets/ic_lt_mask.svg', #5566FF, 16px);
}
.i-apple {
  @include mask('~src/assets/apple.svg', #000000, 32px);
}
.i-eye {
  background-image: url(~src/assets/eye.svg);
}

.i-thumb-down-mask {
  $path: '~src/assets/thumb_down.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}
.i-bin-mask {
  $path: '~src/assets/bin.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}
.i-dot-menu-mask {
  $path: '~src/assets/3-dot-menu.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}

.i-plus {
  background-image: url('~src/assets/plus.svg');
}
.i-location {
  background-image: url('~src/assets/location.svg');
}
.i-location-mask {
  $path: '~src/assets/location.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}
.i-plus-mask {
  $path: '~src/assets/plus.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}

.i-male-mask {
  $path: '~src/assets/ic_man.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}
.i-female-mask {
  $path: '~src/assets/ic_woman.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
}

.i-edit {
  background-image: url('~src/assets/edit.svg');
}
.i-share {
  background-image: url('~src/assets/share.svg');
}
.i-answers {
  background-image: url('~src/assets/icon-answers.svg');
}

.flip-x {
  transform: scaleX(-100%);
}


.i-flag-ru {
  background-image: url(~src/assets/chat-options/russia.png);
}
.i-flag-us {
  background-image: url(~src/assets/chat-options/united_states.png);
}
.i-flag-ua {
  background-image: url(~src/assets/chat-options/ukraine.png);
}

.i-button-loader {
  $path: '~src/assets/button_loader.svg';
  -webkit-mask: url($path) no-repeat center;
  mask: url($path) no-repeat center;
  animation: rotating 1s infinite linear;
  background-color: #FFF;
  &.dark {
    background-color: #393939;
  }
}

.i-contact-email {
  background-image: url(~src/assets/contact-email.svg);
}
.i-contact-instagram {
  background-image: url(~src/assets/contact-instagram.svg);
}
.i-contact-linkedin {
  background-image: url(~src/assets/contact-linkedin.svg);
}
.i-contact-phone {
  background-image: url(~src/assets/contact-phone.svg);
}
.i-contact-skype {
  background-image: url(~src/assets/contact-skype.svg);
}
.i-contact-telegram {
  background-image: url(~src/assets/contact-telegram.svg);
}
.i-contact-viber {
  background-image: url(~src/assets/contact-viber.svg);
}
.i-contact-whatsapp {
  background-image: url(~src/assets/contact-whatsapp.svg);
}
